import { Button, Input, Image, Modal, Table, Tooltip, Switch, DatePicker } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import EditIcon from "../../assets/images/edit.svg";
import deleteWhiteIcon from "../../assets/images/icon/deleteWhiteIcon.png";
import notfound from "../../assets/images/not_found.png";
import Plus from "../../assets/images/plus.svg";
import DeleteModal from "../../components/DeleteModal";
import SectionWrapper from "../../components/SectionWrapper";
import apiPath from "../../constants/apiPath";
import { DeleteBanner } from "../../constants/var";
import { useAppContext } from "../../context/AppContext";
import lang from "../../helper/langHelper";
import { Severty, ShowToast } from "../../helper/toast";
import useRequest from "../../hooks/useRequest";
import AdvertisementBannerForm from "./AdvertisementBannerForm";
import useDebounce from "../../hooks/useDebounce";

const { RangePicker } = DatePicker;

const BannerManager = ({ sectionName }) => {

  const Search = Input.Search;
  const { country } = useAppContext()
  const [pagination, setPagination] = useState({
    current: 1,
    total: 10,
  });
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [selectedAddBanner, setSelectedAddBanner] = useState();
  const [visible, setVisible] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const debouncedSearchText = useDebounce(searchText, 300);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const { request } = useRequest();

  const handleChange = (pagination, filters, sorter) => {
    fetchData(pagination, filters)
  };

  const handleChangeStatus = (id) => {
    request({
      url: apiPath.banner + "/status/" + id,
      method: "PUT",
      onSuccess: (data) => {
        setLoading(false);
        setRefresh((prev) => !prev);
        ShowToast(data.message, Severty.SUCCESS);
      },
      onError: (error) => {
        console.log(error);
        setLoading(false);
        ShowToast(error, Severty.ERROR);
      },
    });
  };

  const onDelete = (record) => {
    request({
      url: apiPath.banner + "/" + record?._id,
      method: "DELETE",
      onSuccess: (data) => {
        setLoading(false);
        if (data.status) {
          ShowToast(data.message, Severty.SUCCESS)
        } else {
          ShowToast(data.message, Severty.ERROR)
        }

        setRefresh((prev) => !prev);
      },
      onError: (error) => {
        setLoading(false);
        ShowToast(error, Severty.ERROR);
      },
    });
  };

  const fetchData = (pagination, filters) => {
    const filterActive = filters ? filters.is_active : null;
    setLoading(true)

    request({
      url:
        apiPath.banner +
        `?status=${filterActive ? filterActive?.join(",") : ''}&page=${pagination ? pagination.current : 1}&pageSize=${pagination && pagination?.total ? pagination?.total : 10
        }&search=${debouncedSearchText}&start_date=${startDate ? startDate : ''}&end_date=${endDate ? endDate : ''}`,
      method: "GET",
      onSuccess: (data) => {
        setList(data.data);
        setLoading(false)
      },
      onError: (err) => {
        console.log(err);
        setLoading(false)
      },
    });
  };

  const columns = [
    {
      title: "Banner",
      dataIndex: "image",
      key: "image",
      render: (_, { image }) => (
        <Image
          width={50}
          src={image ? image : notfound}
          className="table-img"
        />
      ),
    },
    {
      title: "Banner For Products",
      dataIndex: "banner_for",
      key: "name",
      render: (_, { banner_for }) => {
        return banner_for?.length ? <span className="cap">{banner_for?.map((pr, i) => {
          if (banner_for?.length == 1) return "[ " + pr?.parent_id?.name + " ]";
          if (i == 0) return "[ " + pr?.parent_id?.name + ", ";
          if (i == banner_for?.length - 1) return pr?.parent_id?.name + " ]";
          return pr?.parent_id?.name + ",  "
        })}</span> : "-";
      },
    },
    {
      title: "Status",
      key: "is_active",
      dataIndex: "is_active",
      className: "driver-status",
      render: (_, { _id, is_active }) => {
        return (
          <Switch
            onChange={() => {
              handleChangeStatus(_id);
            }}
            checked={is_active}
          />
        );
      },
      filters: [
        {
          text: 'Active',
          value: true,
        },
        {
          text: 'In Active',
          value: false,
        },
      ]
    },
    {
      title: "Created On",
      key: "created_at",
      dataIndex: "created_at",
      render: (_, { created_at }) => {
        return moment(created_at).format("DD-MMM-YYYY");
      },
    },
    {
      title: "Action",
      fixed: "right",
      key: "action",
      render: (_, record) => {
        return (
          <div div className="d-flex justify-contenbt-start">
            <Tooltip
              title={"Edit"}
              color={"purple"}
              key={"update"}
            >
              <Button
                title="Edit"
                className="edit-cls btnStyle primary_btn"
                onClick={() => {
                  setSelectedAddBanner(record);
                  setVisible(true);
                }}
              >
                <i class="far fa-edit"></i>
              </Button>
            </Tooltip>

            <Tooltip title={"Delete"} color={"purple"} key={"Delete"}>
              <Button
                title="Delete"
                className="btnStyle deleteDangerbtn"
                onClick={(e) => {
                  setSelectedAddBanner(record);
                  setShowDelete(true);
                }}
              >
                <i class="far fa-trash-alt"></i>
              </Button>
            </Tooltip>
          </div>
        );
      },
    },
  ];

  const onSearch = (e) => {
    setSearchText(e.target.value);
    setPagination({ current: 1, pageSize: 10 });
  };

  useEffect(() => {
    fetchData(pagination);
  }, [refresh, debouncedSearchText, startDate, endDate]);

  const handleChangeDate = (e) => {
    if (e != null) {
      setStartDate(moment(e[0]._d).format("YYYY-MM-DD"))
      setEndDate(moment(e[1]._d).format("YYYY-MM-DD"))
    } else {
      setStartDate()
      setEndDate()
    }
  };

  return (
    <>
      <SectionWrapper
        cardHeading={lang("Banner Manager")}
        extra={
          <>
            <div className="w-100 text-head_right_cont">
              <Search
                className="search-input"
                allowClear
                size="large"
                onChange={onSearch}
                value={searchText}
                onPressEnter={onSearch}
                placeholder="Search By Product Name & SKU Number"
              />
              <RangePicker disabledDate={(current) => current.isAfter(Date.now())} onChange={handleChangeDate} />
              <Button
                className="primary_btn btnStyle"
                onClick={(e) => {
                  setVisible(true);
                }}
              >
                <span className="add-Ic">
                  <img src={Plus} />
                </span>
                {lang("Add Banner")}
              </Button>
            </div>
          </>
        }
      >
        <div className="table-responsive customPagination">
          <Table
            loading={loading}
            columns={columns}
            dataSource={list}
            pagination={{
              defaultPageSize: 10,
              responsive: true,
              total: pagination.total,
              showQuickJumper: true,
            }}
            onChange={handleChange}
            className="ant-border-space"
          />
        </div>
      </SectionWrapper>

      {visible && (
        <AdvertisementBannerForm
          type={sectionName}
          sectionName={sectionName}
          show={visible}
          hide={() => {
            setSelectedAddBanner();
            setVisible(false);
          }}
          ant-btn-default
          data={selectedAddBanner}
          refresh={() => setRefresh((prev) => !prev)}
        />
      )}

      {showDelete && (
        <DeleteModal
          title={lang("Delete Banner")}
          subtitle={lang(`Are you sure you want to Delete this Banner?`)}
          show={showDelete}
          hide={() => {
            setShowDelete(false);
            setSelectedAddBanner();
          }}
          reasons={DeleteBanner}
          onOk={() => onDelete(selectedAddBanner)}
        />
      )}
    </>
  );
};

export default BannerManager;
