import React, { useEffect, useState } from "react";
import { Layout, Typography, Spin, Row, Col } from "antd";
import useRequest from "../../hooks/useRequest";
import apiPath from "../../constants/apiPath";
import lang from "../../helper/langHelper";
import './PrivacyPolicy.css';

const { Content } = Layout;
const { Title, Paragraph } = Typography;

const Privacy_policy = () => {
    const { request } = useRequest();
    const [description, setDescription] = useState();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        request({
            url: apiPath.common.content + "/privacy-policy",
            method: "GET",
            onSuccess: (data) => {
                if (data.status) {
                    if (data.data?.is_active) {
                        setDescription(data.data?.description);
                    } else {
                        setDescription("No Content Found!");
                    }
                } else {
                    setDescription("Something Went Wrong!!");
                }
                setLoading(false);
            },
            onError: (err) => {
                console.log(err);
                setDescription("Something Went Wrong!!");
                setLoading(false);
            }
        })
    }, []);

    return (
        <Layout>
            <Content className="privacy-policy-content">
                {/* <div className="pageImgOuter">
                    <img 
                        className="pageImage" 
                        src="https://www.gaadizo.com/gaadizo-car-service-repairs/assets/media/components/b-main-slider/dent%20repairs.jpg" 
                        alt="Privacy Policy"
                    />
                    <div className="pageTitle">{lang("Privacy Policy")}</div>
                </div> */}
                <div style={{border: "2px solid black"}} className="contentWrapper">
                    <Row justify="center">
                        <Col xs={24} md={18}>
                            <div style={{marginTop: "50px"}} className="sectionHeading">
                                <Title>{lang("Privacy Policy")} {lang("of")} Quickly Online</Title>
                                <div className="titleDesign"></div>
                            </div>
                            <div className="aboutOuter">
                                {loading ? (
                                    <Spin size="large" />
                                ) : (
                                    <div className="description" dangerouslySetInnerHTML={{ __html: description }}>
                                    </div>

                                    // <Paragraph className="description" dangerouslySetInnerHTML={{ __html: description }} />
                                )}
                            </div>
                        </Col>
                    </Row>
                </div>
            </Content>
        </Layout>
    );
}

export default Privacy_policy;
