export const Last20Years = Array.from({ length: 20 }, (_, index) => (new Date().getFullYear() - index).toString());
export const Months = [
  { label: "January", value: 1 },
  { label: "February", value: 2 },
  { label: "March", value: 3 },
  { label: "April", value: 4 },
  { label: "May", value: 5 },
  { label: "June", value: 6 },
  { label: "July", value: 7 },
  { label: "August", value: 8 },
  { label: "September", value: 9 },
  { label: "October", value: 10 },
  { label: "November", value: 11 },
  { label: "December", value: 12 },
]; 

export const BlockRest=[
  "Quality and Safety Issues",
  "Chronic Order Delays",
  "Misrepresentation",
  "Violation of Platform Policies",
  "Illegal Activities"
]

export const BlockDriver =[
  "Consistent Order Delays",
  "Poor Customer Service",
  "Reckless Driving",
  "Fraudulent Activity",
  "Violation of Platform Policies"
]

export const CancelOrder =[
  "Payment Issues",
  "Unavailability of Items",
  "Delivery Address Inaccuracy",
  "Security or Fraud Concerns",
  "Violation of Platform Policies"
] 

export const BlockSubAdmin =[
  "Violation of Company Policies",
  "Breach of Security Protocols",
  "Misuse of Administrative Privileges",
  "Failure to Fulfill Assigned Responsibilities",
  "Engagement in Unethical Behavior"
]

export const DeleteBanner = [
  "Expired Promotion or Event",
  "Incorrect Information",
  "Inappropriate Content",
  "Rebranding or Campaign Change",
  "Design or Layout Issues"
];

export const rolesOptions = [
    { name: "dashboard-management", label: "Dashboard Management" },
    { name: "category-management", label: "Category Management" },
    { name: "vendor-manager", label: "Vendor Management" },
    { name: "catalogue-manager", label: "Product Management" },
    { name: "attribute-manager", label: "Attribute Management" },
    { name: "customer-manager", label: "Customer Management" },
    { name: "deal-manager", label: "Deal Management" },
    { name: "order-manager", label: "Order Management" },
    { name: "driver-manager", label: "Driver Management" },
    { name: "vehicle-manager", label: "Vehicle Management" },
    { name: "cms-manager", label: "CMS Management" },
    { name: "email-template-manager", label: "Email Template Management" },
    { name: "transaction-manager", label: "Transaction Management" },
    { name: "revenue-stream-manager", label: "Revenue Management" },
    { name: "discount-manager", label: "Discount Management" },
  ]; 

  export const Palestine = {
    "id": "646b2e0f46865f1f65565346",
    "name": "Palestine"
  }