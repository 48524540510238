import { Col, DatePicker, Form, Input, Modal, Row, Select } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { Severty, ShowToast } from "../../../helper/toast";
import useRequest from "../../../hooks/useRequest";
import UploadImage from "./_UploadImage";

const AddForm = ({ api, show, hide, data, refresh }) => {
  const [form] = Form.useForm();
  const { request } = useRequest();
  const [image, setImage] = useState();
  const [loading, setLoading] = useState(false);
  const [selectedCurrency, setselectedCurrency] = useState("");
  const [mobileNumber, setMobileNumber] = useState({
    mobile_number: "",
    country_code: "",
  });

  const handleChange = (value, data) => {
    var country_code = data.dialCode;
    setMobileNumber({
      country_code: country_code,
      mobile_number: value.slice(data.dialCode.length),
    });
  };

  useEffect(() => {
    if (!data) return;
    console.log(data, "-----");
    form.setFieldsValue({
      ...data,
      gold_purity: data?.gold_purity,
      currency: data?.currency,
      balance: data?.balance,
      mobile: data.country_code + data.mobile_number,
      // dob: moment(data.dob),
    });

    setselectedCurrency(data?.currency ?? "")
    setImage(
      data?.image?.includes(
        "https://api-ap-south-mum-1.openstack.acecloudhosting.com:8080/"
      )
        ? data?.image
        : "https://api-ap-south-mum-1.openstack.acecloudhosting.com:8080/" +
            data?.image
    );
    setMobileNumber({
      mobile_number: data.mobile_number,
      country_code: data.country_code,
    });
  }, [data]);

  const onCreate = (values) => {
    const { name, email, dob, gender, gold_purity, balance, cap, currency } = values;

    const payload = {};
    payload.name = name;
    payload.dob = dob ? moment(dob).format("YYYY-MM-DD") : null;
    payload.mobile_number = mobileNumber.mobile_number;
    payload.country_code = mobileNumber.country_code;
    payload.email = email;
    payload.gender = gender;
    payload.image = image;
    payload.cap = cap;
    payload.gold_purity = gold_purity;
    payload.balance = parseInt(balance);
    payload.currency = currency;

    console.log(":: payload :: ", payload, ":: payload :: ");

    setLoading(true);
    request({
      url: `${data ? api.addEdit + "/" + data._id : api.addEdit}`,
      method: data ? "PUT" : "POST",
      data: payload,
      onSuccess: (data) => {
        setLoading(false);
        if (data.status) {
          ShowToast(data.message, Severty.SUCCESS);
          hide();
          refresh();
        } else {
          ShowToast(data.message, Severty.ERROR);
        }
      },
      onError: (error) => {
        ShowToast(error.response.data.message, Severty.ERROR);
        setLoading(false);
      },
    });
  };

  return (
    <Modal
      open={show}
      width={750}
      okText={data ? "Update" : "Add"}
      onCancel={hide}
      okButtonProps={{
        form: "create",
        htmlType: "submit",
        loading: loading,
      }}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="tab_modal"
    >
      <Form
        id="create"
        form={form}
        onFinish={onCreate}
        layout="vertical"
        initialValues={{
          dob: moment("1990-01-01", "YYYY-MM-DD"),
        }}
      >
        <div className="add_user_title">
          <h4 className="modal_title_cls">{`${
            data ? "Edit Customer" : "Add Customer"
          }`}</h4>
        </div>
        <Row gutter={[16, 0]}>
          <Col span={24}>
            <div className="text-center">
              <Form.Item className="upload_wrap" name="image">
                <UploadImage value={image} setImage={setImage} />
              </Form.Item>
            </div>
          </Col>

          <Col span={24} sm={12}>
            <Form.Item
              label={`Name`}
              name="name"
              rules={[
                { required: true, message: "Please enter name" },
                {
                  max: 200,
                  message: "Name should not contain more then 200 characters!",
                },
                {
                  min: 2,
                  message: "Name should contain at least 2 characters!",
                },
                {
                  pattern: new RegExp(/^[A-Z a-z]+$/),
                  message: "Alphabets only!",
                },
              ]}
              normalize={(value) => value?.trimStart()}
            >
              <Input autoComplete="off" placeholder={`Enter Name`} />
            </Form.Item>
          </Col>

          <Col span={24} lg={12} sm={12} className="flagMobileNumber">
            <Form.Item
              label="Mobile Number"
              name="mobile"
              rules={[
                {
                  required: true,
                  validator: (rule, value) => {
                    if (!value) {
                      return Promise.reject("Please enter phone number");
                    }
                    if (!/^\d{8,12}$/.test(mobileNumber.mobile_number)) {
                      return Promise.reject(
                        "Phone number must be between 8 and 12 digits"
                      );
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <PhoneInput
                inputProps={{
                  name: "mobile",
                  required: true,
                  autoFocus: false,
                  autoFormat: false,
                  autoComplete: "off",
                }}
                isValid={(value, country) => {
                  if (value.match(/1234/)) {
                    return "Invalid value: " + value + ", " + country.name;
                  } else if (value.match(/1234/)) {
                    return "Invalid value: " + value + ", " + country.name;
                  } else {
                    return true;
                  }
                }}
                country={"us"}
                onChange={handleChange}
              />
            </Form.Item>
          </Col>

          <Col span={24} lg={12} sm={12}>
            <Form.Item
              label="Email ID"
              name="email"
              rules={[
                { type: "email", message: "Please enter valid email!" },
                { required: true, message: "Please enter email!" },
              ]}
            >
              <Input autoComplete="off" placeholder="Enter Email Address" />
            </Form.Item>
          </Col>

          <Col span={24} sm={12}>
            <Form.Item
              label="Gold Purity"
              name="gold_purity"
              rules={[
                { required: true, message: "Please Select Gold Purity!" },
              ]}
            >
              <Select
                onChange={(key) => form.setFieldValue("gold_purity", key)}
                filterOption={(input, option) =>
                  option?.label?.toLowerCase()?.indexOf(input?.toLowerCase()) >=
                  0
                }
                autoComplete="off"
                placeholder="Select Gold Purity"
                showSearch
              >
                {["995", "999"].map((pur, idx) => {
                  return (
                    <Select.Option key={idx} label={pur} value={pur}>
                      {pur}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>

          {!data && (
            <>
              <Col span={24} sm={12}>
                <Form.Item
                  label="Currency"
                  name="currency"
                  rules={[
                    { required: true, message: "Please Select Currency!" },
                  ]}
                >
                  <Select
                    onChange={(key) => {form.setFieldValue("currency", key);setselectedCurrency(key)}}
                    filterOption={(input, option) =>
                      option?.label
                        ?.toLowerCase()
                        ?.indexOf(input?.toLowerCase()) >= 0
                    }
                    autoComplete="off"
                    placeholder="Select Currency"
                    showSearch
                  >
                    {["USD", "HKD", "AED", "SGD"].map((cur, idx) => {
                      return (
                        <Select.Option key={idx} label={cur} value={cur}>
                          {cur}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>

              <Col span={24} sm={12}>
                <Form.Item
                  label={`Balance Amount ${selectedCurrency ? `(${selectedCurrency})` : ""}`}
                  name="balance"
                  rules={[
                    {
                      required: true,
                      message: "Please enter balance amount!",
                    },
                  ]}
                  normalize={(value) => value?.trimStart()}
                >
                  <Input
                    min={0}
                    type="number"
                    autoComplete="off"
                    placeholder={`Enter Balance Amount`}
                  />
                </Form.Item>
              </Col>
            </>
          )}

          <Col span={24} lg={12} sm={12}>
            <Form.Item
              label="Buy Limit (Kg)"
              name="cap"
              rules={[
                { required: true, message: "Please Enter Buy Limit!" },
              ]}
            >
              <Input
                min={0}
                type="number"
                autoComplete="off"
                placeholder="Enter Cap Limit"
              />
            </Form.Item>
          </Col>
          
          <Col span={24} lg={12} sm={12}>
            <Form.Item
              label="Sell Limit (Kg)"
              name="sell-cap"
              rules={[
                { required: true, message: "Please Enter Sell Limit!" },
              ]}
            >
              <Input
                min={0}
                type="number"
                autoComplete="off"
                placeholder="Enter Cap Limit"
              />
            </Form.Item>
          </Col>

          <Col span={24} sm={12}>
            <Form.Item
              label="Weight Unit"
              name="weight_unit"
              rules={[
                { required: true, message: "Please Select Weight Unit!" },
              ]}
            >
              <Select
                onChange={(key) => form.setFieldValue("weight_unit", key)}
                filterOption={(input, option) =>
                  option?.label?.toLowerCase()?.indexOf(input?.toLowerCase()) >=
                  0
                }
                autoComplete="off"
                placeholder="Select Weight Unit"
                showSearch
              >
                {["KG", "Oz"].map((unit, idx) => {
                  return (
                    <Select.Option key={idx} label={unit} value={unit}>
                      {unit}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default AddForm;
