import {
  Col,
  Form,
  Input,
  Modal,
  Row,
  Button,
  Card,
  Table,
  Tooltip,
  Select,
} from "antd";
import React, { useEffect, useState } from "react";
import "react-phone-input-2/lib/style.css";
import { Severty, ShowToast } from "../../../helper/toast";
import useRequest from "../../../hooks/useRequest";
import WalletIcon from "../../../assets/images/wallet.png";
import { useNavigate, useParams } from "react-router";
import apiPath from "../../../constants/apiPath";
import moment from "moment";
import Plus from "../../../assets/images/plus.svg";

const ViewWallet = () => {
  const params = useParams();
  const [form] = Form.useForm();
  const { request } = useRequest();
  const [show, setShow] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [selected, setSelected] = useState();
  const [money, setMoney] = useState();
  const [wallets, setWallets] = useState([]);
  const navigate = useNavigate();
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
  const [selectedCurrency, setselectedCurrency] = useState("");


  const columns = [
    // {
    //   title: "S. No",
    //   dataIndex: "index",
    //   key: "index",
    //   render: (value, item, index) =>
    //     pagination.current === 1
    //       ? index + 1
    //       : (pagination.current - 1) * 10 + (index + 1),
    // },
    {
      title: "Wallet Currency",
      dataIndex: "balance",
      key: "balance",
      render: (_, { currency }) => {
        return <span>{currency || "USD"}</span>;
      },
    },
    {
      title: "Wallet Balance",
      dataIndex: "balance",
      key: "balance",
      render: (_, { balance }) => {
        return <span>{balance?.toFixed(2)}</span>;
      },
    },
    {
      title: "Total Transactions",
      dataIndex: "total_transaction",
      key: "total_transaction",
      render: (_, record) => {
        return (
          <div>  
         <span className="text-uppercase">{record?.total_transactions || "1"}</span>
         
        </div>
     

        );
      },
    },
    {
      title: "Date & Time",
      dataIndex: "date",
      key: "date",
      render: (_, { created_at }) => {
        return (
          <span>
            {created_at
              ? moment(created_at).format("ll")
              : moment(Date.now()).format("ll")}
          </span>
        );
      },
    },
    {
      title: "Action",
      fixed: "right",
      key: "action",
      render: (_, record) => {
        return (
          <div div className="d-flex justify-contenbt-start">
            <Tooltip title={"Add Money"} color={"purple"} key={"Wallet-a"}>
              <Button
                className="edit-cls primary_btn"
                onClick={() => {
                  setSelected(record);
                  setShow(true);
                }}
              >
               
                <i
                  style={{ fontSize: "medium", color: "#000" }}
                  class="fa fa-wallet"
                ></i>
              </Button>
            </Tooltip>
            <Tooltip title={"View"} color={"purple"} key={"Wallet"}>
          <Button
            className="edit-cls primary_btn"
            onClick={() => {
              navigate(`/customer/view/${params.id}`, {
                state: { wallet: record },
              });
            }}
          >
            <i
              style={{ fontSize: "medium", color: "#000" }}
              class="fa fa-eye"
            ></i>
          </Button>
        </Tooltip>
           
          </div>
        );
      },
    },
  ];

  const handleAdd = (money) => {
    // const { money } = values;
    const payload = { money: parseInt(money) };
    request({
      url: apiPath.addMoney + `/${selected?._id}`,
      method: "PUT",
      data: payload,
      onSuccess: (data) => {
        if (data.status) {
          ShowToast(data.message, Severty.SUCCESS);
          setShow(false);
          setRefresh((x) => !x);
          form.resetFields();
          setMoney()
        } else {
          ShowToast(data.message, Severty.ERROR);
        }
      },
      onError: (error) => {
        ShowToast(error.response.data.message, Severty.ERROR);
      },
    });
  };

  const handleRemove = (money) => {
    // const { money } = values;
    const payload = { money: parseInt(money) };
    request({
      url: apiPath.removeMoney + `/${selected?._id}`,
      method: "PUT",
      data: payload,
      onSuccess: (data) => {
        if (data.status) {
          ShowToast(data.message, Severty.SUCCESS);
          setShow(false);
          setRefresh((x) => !x);
          form.resetFields();
          setMoney()
        } else {
          ShowToast(data.message, Severty.ERROR);
        }
      },
      onError: (error) => {
        ShowToast(error.response.data.message, Severty.ERROR);
      },
    });
  };

  const handleAddWallet = (values) => {
    const payload = {
      balance: values?.balance,
      currency: values?.currency,
    };

    console.log(payload, "----------> payload");
    request({
      url: apiPath.wallet + `/${params.id}`,
      method: "POST",
      data: payload,
      onSuccess: (data) => {
        if (data.status) {
          ShowToast(data.message, Severty.SUCCESS);
          setVisible(false);
          setRefresh((x) => !x);
          form.resetFields();
          setselectedCurrency("")
        } else {
          ShowToast(data.message, Severty.ERROR);
        }
      },
      onError: (error) => {
        ShowToast(error.response.data.message, Severty.ERROR);
      },
    });
  };

  const getWallets = (id) => {
    setLoading(true);
    request({
      url: apiPath.wallet + `/${id}`,
      method: "GET",
      onSuccess: (data) => {
        setLoading(false);
        if (data?.statusText == "true") {
          setWallets(data?.data?.data);
          setPagination((prev) => ({
            ...prev,
            current: 1,
            pageSize: 10,
            total: data.total,
          }));
        } else {
          ShowToast(data?.message, Severty.ERROR);
        }
      },
      onError: (err) => {
        setLoading(false);
        ShowToast(err?.message, Severty.ERROR);
      },
    });
  };

  useEffect(() => {
    getWallets(params.id);
  }, [params.id, refresh]);

  return (
    <>
      <Card
        title="Wallet List"
        bordered={false}
        className="criclebox tablespace mb-24"
        extra={
          <>
            <div className="button_group justify-content-end w-100">
              <Button
                className="primary_btn btnStyle"
                onClick={(e) => {
                  setVisible(true);
                }}
              >
                <span className="add-Ic">
                  <img src={Plus} />
                </span>
                Add Wallet
              </Button>
            </div>
          </>
        }
      >
        <div className="tabled">
          <Row gutter={[24, 0]}>
            <Col xs={24} xl={24}>
            <div className="table-responsive customPagination withOutSearilNo">
              <Table
                className="ant-border-space main-table"
                loading={loading}
                columns={columns}
                dataSource={wallets}
                pagination={true}
              />
              </div>
            </Col>
          </Row>
        </div>
      </Card>

      <Modal
        open={show}
        width={800}
        cancelText={"Cancel"}
        onCancel={() => setShow(false)}
        footer={[
          <Button key="cancel" onClick={() => setShow(false)}>
            Cancel
          </Button>,
        ]}
        className="tab_modal"
      >
        <Form layout="vertical">
          <div className="add_user_title">
            <h4 className="modal_title_cls">My Wallet</h4>
          </div>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <div className="addwalet">
                <img src={WalletIcon} />
                <div className="wallet-detail">
                  <h6>Wallet Balance</h6>
                  <h4>
                    {selected?.balance?.toFixed(2)} {selected?.currency || "$"}
                  </h4>
                </div>
              </div>
            </Col>
            <Col span={24} lg={24}>
              <div className="money-adding">
                <h6>Update Balance</h6>
                <div className="balance-add">
                  <Form.Item
                    className="w-100"
                    rules={[
                      { required: true, message: "Please Enter Valid Amount!" },
                    ]}
                  >
                    <Input
                      min={1}
                      value={money}
                      onChange={({ target }) => setMoney(target.value)}
                      type="number"
                      autoComplete="off"
                      placeholder={`Enter balance amount to be updated`}
                    />
                  </Form.Item>
                  <Button
                    onClick={() => {
                      if (!money || money < 1) {
                        ShowToast("Please enter valid amount!", Severty.ERROR);
                      } else {
                        handleAdd(money);
                      }
                    }}
                    className="btnadd-modal"
                  >
                    Add Balance
                  </Button>
                  <Button
                    onClick={() => {
                      if (!money || money < 1) {
                        ShowToast("Please enter valid amount!", Severty.ERROR);
                      } else {
                        handleRemove(money);
                      }
                    }}
                    className="btnadd-modal"
                  >
                    Remove Balance
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
        </Form>
      </Modal>

      <Modal
        open={visible}
        width={500}
        okText={"Add"}
        cancelText={"Cancel"}
        onOk={() => form.submit()}
        onCancel={() => {
          form.resetFields();
          setVisible(false);
          setselectedCurrency("")
        }}
        className="tab_modal"
      >
        <Form
          id="create"
          form={form}
          onFinish={handleAddWallet}
          layout="vertical"
        >
          <div className="add_user_title">
            <h4 className="modal_title_cls">Change Wallet</h4>
          </div>
          <Row gutter={[16, 16]}>
            <Col span={24} lg={24}>
              <Form.Item
                name="balance"
                label={`Balance Amount ${selectedCurrency ? `(${selectedCurrency})` : ""}`}
                className="w-100"
                rules={[
                  // { required: true, message: "Please Enter Valid Amount!" },
                  () => ({
                    validator(_, value) {
                      if (value > 0) {
                        return Promise.resolve();
                      }
                      if (!!value) {
                        return Promise.reject(
                          new Error("Balance should be greater than 0!")
                        );
                      }
                      return Promise.reject(new Error("Please Enter Amount!"));
                    },
                  }),
                ]}
              >
                <Input
                  min={1}
                  type="number"
                  autoComplete="off"
                  placeholder={`Enter Balance Amount`}
                />
              </Form.Item>
            </Col>

            <Col span={24} lg={24}>
              <Form.Item
                name="currency"
                label="Wallet Currency"
                className="w-100"
                rules={[{ required: true, message: "Please Select Currency!" }]}
              >
                <Select
                  filterOption={(input, option) =>
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  autoComplete="off"
                  placeholder="Select Country"
                  showSearch
                  onChange={(value)=>setselectedCurrency(value)}
                >
                  {[
                    "USD",
                    "AED",
                    "HKD",
                    "SGD",
                  ].map((item, idx) => {
                    return (
                      <Select.Option key={item} label={item} value={item}>
                        {item}{" "}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default ViewWallet;
