import {
  Card,
  Col,
  Progress,
  Radio,
  Row,
  Select,
  Skeleton,
  Tabs,
  Typography,
  Descriptions,
  Table,
  Tag,
  Badge,
  Tooltip,
  Dropdown,
  Menu,
  Button,
} from "antd";
import { DownOutlined } from "@ant-design/icons";
import React, { useContext, useEffect, useState } from "react";
import apiPath from "../../constants/apiPath";
import { AppStateContext } from "../../context/AppContext";
import { Severty, ShowToast } from "../../helper/toast";
import useRequest from "../../hooks/useRequest";
import CancelIcon from "../../assets/images/icon/CancelIcon.png";
import LineChart from "./_LineCart";
import { useNavigate } from "react-router";
import { useAuthContext } from "../../context/AuthContext";
import { formatPhone } from "../../helper/functions";
import lang from "../../helper/langHelper";
import { Link } from "react-router-dom";
import moment from "moment";
import { OrderStatus } from "../Order/Index";
import Dashboard from "../../components/Dashboard";
import GoldPriceChart from "../../components/StockChart";
import ImportForm from "../../components/ImportForm";
import ReactHighcharts from "react-highcharts/ReactHighstock.src";
import ChartGold from "../Chart/Index";
const { Title, Text } = Typography;

const years = [2024, 2025];

function Home() {
  const { setPageHeading, country } = useContext(AppStateContext);

  const { request } = useRequest();
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [importModal, showImportModal] = useState(false);
  const [year, setYear] = useState(years[0]);
  const [period, setPeriod] = useState("monthly");
  const { userProfile } = useAuthContext();
  const [dashboard, setDashboard] = useState();
  const [listing, setListing] = useState([]);
  const [stockData, setStockData] = useState([]);
  const [extraKeys, setExtraKeys] = useState({});
  const [openPosition, setOpenPositions] = useState();
  const [currency, setCurrency] = useState("USD");

  const menu = (
    <Menu
      style={{ width: "70px" }}
      onClick={(e) => {
        fetchWalletInfo(e.key);
        setCurrency(e.key);
      }}
    >
      <Menu.Item key="USD">USD</Menu.Item>
      <Menu.Item key="AED">AED</Menu.Item>
      <Menu.Item key="HKD">HKD</Menu.Item>
      <Menu.Item key="SGD">SGD</Menu.Item>
    </Menu>
  );

  const count = [
    {
      today: "Number of Customers",
      title: `${dashboard?.totalCustomer || 0}`,

      icon: <i class="fas fa-user-friends"></i>,
      bnb: "bnb2",
      url: "/customer",
      key: "customer-manager",
    },
    {
      today: "Number of Orders",
      title: `${dashboard?.totalOrders || 0}`,
      icon: <i className="fas fa-people-carry"></i>,
      bnb: "bnb2",
      url: "/order",
      key: "order-manager",
    },
    {
      today: "Pending Orders",
      title: `${extraKeys?.pendingOpenPosition || 0} KG`,
      icon: <i className="fas fa-people-carry"></i>,
      bnb: "bnb2",
      url: "/order",
      key: "order-manager",
    },
    {
      today: "Total Open Position",
      title: `${(openPosition?.average / 32.148)?.toFixed(2) || 0}`,
      totalWeight: `${openPosition?.totalWeight || 0}`,
      icon: (
        <Dropdown className="box-drp" overlay={menu} trigger={["click"]}>
          <Button>
            <DownOutlined />
          </Button>
        </Dropdown>
      ),
      bnb: "bnb2",
      key: "position-manager",
    },
  ];

  const orders = [
    {
      title: "Order id",
      dataIndex: "index",
      key: "index",
      render: (_, { order_id }) =>
        order_id ? <span className="cap">#{order_id}</span> : "-",
    },
    {
      title: "Customer Name",
      dataIndex: "name",
      key: "name",
      render: (_, { user_id }) =>
        user_id ? (
          <div style={{ display: "flex", gap: 2, flexDirection: "column" }}>
            {user_id?.name && <span className="cap">{user_id.name}</span>}
            {user_id?.country_code && user_id?.mobile_number && (
              <span className="cap" style={{ fontSize: "14px", color: "gray" }}>
                (
                {user_id.country_code?.includes("+")
                  ? user_id.country_code
                  : "+" + user_id.country_code}
                ) {user_id.mobile_number}
              </span>
            )}
            {user_id?.email && (
              <span style={{ fontSize: "14px", color: "gray" }}>
                {user_id.email}
              </span>
            )}
          </div>
        ) : (
          "-"
        ),
      sorter: (a, b) => {
        let nameA = a.user_id?.name;
        let nameB = b.user_id?.name;
        if (nameA < nameB) return -1;
        if (nameA > nameB) return 1;
        return 0;
      },
    },
    {
      title: "Quantity",
      key: "order_quantity",
      dataIndex: "order_quantity",
      render: (_, { quantity }) => {
        return <span className="cap">{quantity ?? 0} KG</span>;
      },
    },
    {
      title: "Quality",
      key: "order_quality",
      dataIndex: "order_quality",
      render: (_, { quality }) => {
        return <span className="cap">{quality}</span>;
      },
    },
    {
      title: "Currency",
      dataIndex: "currency",
      key: "currency",
      render: (_, { currency }) => <span className="cap">{currency}</span>,
    },
    {
      title: "Total Amount",
      dataIndex: "amount",
      key: "amount",
      render: (_, { amount }) => (
        <span className="cap">{amount?.toFixed(2) ?? 0.0}</span>
      ),
      sorter: (a, b) => {
        let nameA = a.amount;
        let nameB = b.amount;
        if (nameA < nameB) return -1;
        if (nameA > nameB) return 1;
        return 0;
      },
    },
    {
      title: "Order Status",
      className: "status-bedge",
      key: "created_at",
      render: (_, { status }) => {
        let color;
        if (status === OrderStatus.SUCCESS) {
          color = "green";
        } else if (status === OrderStatus.FAILED) {
          color = "red";
        }
        return status ? (
          <Tag color={color}>{<span className="cap">{status}</span>}</Tag>
        ) : (
          "-"
        );
      },
    },
    {
      title: "Order Date",
      key: "created_at",
      dataIndex: "created_at",
      render: (_, { created_at }) => {
        return (
          <span>
            {moment(created_at).format("ll")},{" "}
            {moment(created_at).format("HH:mm a")}
          </span>
        );
      },
      sorter: (a, b) => a.created_at?.localeCompare(b?.created_at),
    },
  ];

  const fetchWalletInfo = (currency) => {
    request({
      url: apiPath.getOpenPositions,
      method: "POST",
      data: { currency },
      onSuccess: (data) => {
        if (data.status) {
          setOpenPositions(data);
        } else {
          ShowToast(data?.message, Severty.ERROR);
        }
      },
      onError: (err) => {
        ShowToast(err?.message, Severty.ERROR);
      },
    });
  };

  const getStockData = () => {
    request({
      url: apiPath.getStockData,
      method: "GET",
      onSuccess: (data) => {
        if (data?.statusText === "true") {
          let tempData = data?.data?.map((item) => item?.close?.toFixed(2));
          setStockData(tempData || []);
        } else {
          ShowToast(data?.message, Severty.ERROR);
        }
      },
      onError: (error) => {
        ShowToast(error?.message, Severty.ERROR);
      },
    });
  };

  useEffect(() => {
    setPageHeading("Welcome Admin");
    if (!userProfile) return;
    fetchWalletInfo(currency);

    setLoading(true);
    getStockData();
    request({
      url: apiPath.dashboard + `${year ? `?year=${year}` : ""}`,
      method: "GET",
      onSuccess: ({ data }) => {
        setLoading(false);
        setListing(data?.orders);
        setDashboard(data?.data);
        setExtraKeys(data?.extraKeys?.[0]);
      },
      onError: (error) => {
        setLoading(false);
        ShowToast(error, Severty.ERROR);
      },
    });
  }, [userProfile, refresh]);

  // const importStockData = () => {
  //   request({
  //     url: apiPath.importStockData,
  //     method: "POST",
  //     data: { stock_price: [] },
  //     onSuccess: (data) => {
  //       if (data.statusText == "true") {
  //         setStockData(data?.data);
  //         ShowToast(data?.message, Severty.SUCCESS);
  //       } else {
  //         ShowToast(data?.message, Severty.ERROR);
  //       }
  //     },
  //     onError: (err) => {
  //       ShowToast(err?.message, Severty.ERROR);
  //     },
  //   });
  // };

  return (
    <>
      {
        <div className="page-top-space home-card layout-content">
          <div className="mb-24">
            <div className="sub_title">
              <p>Here is the information about all your business</p>
            </div>
            <Row gutter={[24, 0]}>
              <Col xs={24} sm={24} md={24}>
                <Row
                  className="rowgap-vbox"
                  gutter={[24, 16]}
                  // style={{ marginLeft: "0" }}
                >
                  {count.map((c, index) => {
                    if (index == count.length - 1) {
                      return (
                        <Col
                          key={index}
                          xs={24}
                          sm={24}
                          md={12}
                          lg={12}
                          xl={8}
                          xxl={6}
                          className="mb24"
                          style={{ paddingRight: "0px" }}
                        >
                          <Link to={c.url}>
                            <Card
                              bordered={false}
                              className="criclebox"
                              style={{ height: "100%" }}
                            >
                              <div className="number">
                                <Row align="middle" gutter={[24, 0]}>
                                  <Col xs={18}>
                                    <span>
                                      {c.today} ({currency})
                                    </span>
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <span
                                        style={{ margin: "0px 10px 0px 0px" }}
                                      >
                                        Quantity:{" "}
                                      </span>
                                      <Title style={{ margin: 0 }} level={5}>
                                        {c.totalWeight} KG
                                      </Title>
                                    </div>
                                    <div>
                                      <span
                                        style={{ margin: "0px 10px 0px 0px" }}
                                      >
                                        Avg Open Position:{" "}
                                      </span>
                                      <Title style={{ margin: 0 }} level={5}>
                                        {c.title == "NaN" ? 0 : c.title} {currency}
                                      </Title>
                                    </div>
                                  </Col>
                                  <Col xs={6}>
                                    {" "}
                                    <div className="icon-box">{c.icon}</div>
                                  </Col>
                                </Row>
                              </div>
                            </Card>
                          </Link>
                        </Col>
                      );
                    }
                    return (
                      <Col
                        key={index}
                        xs={24}
                        sm={24}
                        md={12}
                        lg={12}
                        xl={8}
                        xxl={6}
                        className="mb24"
                        style={{ paddingRight: "0px" }}
                      >
                        <Link to={c.url}>
                          <Card
                            bordered={false}
                            className="criclebox"
                            style={{ height: "100%" }}
                          >
                            <div className="number">
                              <Row align="middle" gutter={[24, 0]}>
                                <Col xs={18}>
                                  <span>{c.today}</span>
                                  <Title level={3}>{c.title}</Title>
                                </Col>
                                <Col xs={6}>
                                  {" "}
                                  <div className="icon-box">{c.icon}</div>
                                </Col>
                              </Row>
                            </div>
                          </Card>
                        </Link>
                      </Col>
                    );
                  })}
                </Row>
              </Col>
            </Row>
          </div>

          <Row className="mt-3" gutter={[24, 0]}>
            {currency !== "USD" && (
              <Col xs={24} xl={12} lg={24} className="mb-24">
                <ChartGold currency={currency} />
              </Col>
            )}
            <Col
              xs={24}
              xl={currency === "USD" ? 24 : 12}
              lg={24}
              className="mb-24"
            >
              {/* <GoldPriceChart dataSet={stockData} /> */}
              <ChartGold currency={"USD"} />
            </Col>
          </Row>

          <Row className="mt-3" gutter={[24, 0]}>
            <Col span={24}>
              <Card bordered={false} className="criclebox tablespace mb-24">
                <p className="dashboard-table-heading">Recent Orders (10) </p>
                <div className="table-responsive">
                  <Table
                    loading={loading}
                    columns={orders}
                    dataSource={listing}
                    pagination={false}
                    className="ant-border-space"
                  />
                </div>
              </Card>
            </Col>
          </Row>

          {/* <Row className="mt-3" gutter={[24, 0]}>
            <Col xs={24} xl={12} lg={24} className="mb-24">
              <Card bordered={false} className="criclebox h-full">
                <div className="graph-title">
                  <Title level={5}>{lang("Revenue Graph for Sales")}</Title>
                  <Row style={{ justifyContent: "end" }}>
                    <Radio.Group defaultValue="month" buttonStyle="solid">
                      <Radio.Button
                        onClick={() => setPeriod("today")}
                        value="today"
                      >
                        {lang("Today")}
                      </Radio.Button>
                      <Radio.Button
                        onClick={() => setPeriod("yesterday")}
                        value="yesterday"
                      >
                        {lang("Yesterday")}
                      </Radio.Button>
                      <Radio.Button
                        onClick={() => setPeriod("weekly")}
                        value="weekly"
                      >
                        {lang("Last Month")}
                      </Radio.Button>
                      <Radio.Button
                        onClick={() => setPeriod("monthly")}
                        value="monthly"
                      >
                        {lang("Last Year")}
                      </Radio.Button>
                    </Radio.Group>
                  </Row>
                </div>
                {loading ? (
                  [1, 2, 3].map((item) => <Skeleton active key={item} />)
                ) : (
                  <>
                    {" "}
                    <LineChart period={period} borderColor="#1EB564" />{" "}
                  </>
                )}
              </Card>
            </Col>
          </Row> */}
        </div>
      }

      {importModal && (
        <ImportForm
          path={apiPath.importStockData}
          show={importModal}
          sectionName={"Dashboard"}
          existingData={[]}
          hide={() => {
            showImportModal(false);
          }}
          updateData={(data) => {
            console.log(data);
            let tempData = data?.map((item) => item?.close?.toFixed(2));
            setStockData(tempData || []);
            setRefresh((prev) => !prev);
          }}
        />
      )}
    </>
  );
}

export default Home;
