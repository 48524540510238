import React, { createContext, useState, useContext, useEffect } from "react";

export const AppStateContext = createContext();

export const AppContextProvider = ({ children }) => {

  const [pageHeading, setPageHeading] = useState("Heading");
  const [language, setLanguage] = useState("en");
  const [loading, setLoading] = useState(true);

  const [country, setCountry] = useState({
    country_id: undefined,
    currency: undefined,
    data: undefined
  });

  useEffect(() => {
    setLoading(true)
    let lang = localStorage.getItem("languageSet")
    lang = lang ? lang : 'en';

    if (lang == "ar") {
      
      import('../assets/styles/rtl.css') 
      
    } else {
      import('../assets/styles/main.css')
    }

    setTimeout(()=>setLoading(false),200)
    setLanguage(lang)
  }, [])

  return (
    <AppStateContext.Provider
      value={{
        pageHeading,
        setPageHeading,
        setCountry,
        country,
        language,
        setLanguage
      }}
    >
      {loading ? null : children}
    </AppStateContext.Provider>
  );
};

export const useAppContext = () => {
  return useContext(AppStateContext);
};

